import { createAction, createReducer } from '@reduxjs/toolkit'
import { LOCALES } from 'config/constants'
import { isDiff, isPresent } from 'lib/utils'
import { AppState } from '.'

export type IAuthState = {
  lng: 'ru' | 'en' | null
  city: string | null
  anonToken: string | null
  userToken: string | null
}

/*
 * Actions
 */
export let setLng =
  createAction<NonNullable<IAuthState['lng']>>('auth/setLng')

export let setCity = createAction<IAuthState['city']>('auth/setCity')

export let setAnonToken = createAction<
  NonNullable<IAuthState['anonToken']>
>('auth/setAnonToken')

export let setUserToken = createAction<
  NonNullable<IAuthState['userToken']>
>('auth/setUserToken')

export let removeUserToken = createAction('auth/removeUserToken')

/*
 * Reducer
 */
let isBrowser =
  isDiff(typeof window, 'undefined') &&
  isDiff(typeof window.document, 'undefined') &&
  isDiff(typeof window.document.createElement, 'undefined')

let initLng
let initCity
let initAnonToken
let initUserToken
if (isBrowser) {
  initLng = localStorage.getItem('lng')
  initCity = localStorage.getItem('city')
  initAnonToken = localStorage.getItem('anonToken')
  initUserToken = localStorage.getItem('userToken')
}

let initialState: IAuthState = {
  lng: LOCALES.includes(initLng ?? '')
    ? (initLng as IAuthState['lng'])
    : null,
  //city: toKeys(CITIES).includes((initCity ?? '') as any)
  city: initCity ? (initCity as IAuthState['city']) : null,
  anonToken: initAnonToken ?? null,
  userToken: initUserToken ?? null,
}

export let authReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(setLng, (state, { payload }) => {
      state.lng = payload
      localStorage.setItem('lng', state.lng)
    })

    .addCase(setCity, (state, { payload }) => {
      state.city = payload
      isPresent(state.city)
        ? localStorage.setItem('city', state.city)
        : localStorage.removeItem('city')
    })

    .addCase(setAnonToken, (state, { payload }) => {
      state.anonToken = payload
      localStorage.setItem('anonToken', state.anonToken)
    })

    .addCase(setUserToken, (state, { payload }) => {
      localStorage.removeItem('anonToken')
      state.userToken = payload
      state.anonToken = null
      localStorage.setItem('userToken', state.userToken)
    })

    .addCase(removeUserToken, (state) => {
      localStorage.removeItem('userToken')
      state.userToken = null
    })
})

/*
 * Selectors
 */
export let selectAnonToken = (state: AppState) => state.auth.anonToken
export let selectUserToken = (state: AppState) => state.auth.userToken
export let selectLng = (state: AppState) => state.auth.lng
export let selectCity = (state: AppState) => state.auth.city

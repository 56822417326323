import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { isDiff } from 'lib/utils'
import * as api from './api'
import { authReducer } from './auth'
import { checkoutReducer } from './checkout'
import { uiReducer } from './ui'

type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>

let makeStore = () => {
  return configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      ui: uiReducer,
      auth: authReducer,
      checkout: checkoutReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    devTools: isDiff(process.env.NODE_ENV, 'production'),
  })
}

export let store = makeStore()
setupListeners(store.dispatch)

import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { BASE_URL } from 'config/constants'
import { isPresent } from 'lib/utils'
import { AppState } from 'store'

let baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    let { anonToken, userToken, city } = (getState() as AppState).auth

    isPresent(userToken)
      ? headers.set('user-token', userToken)
      : isPresent(anonToken) &&
        headers.set('anonymouse-token', anonToken)

    isPresent(city) && headers.set('city', city)

    return headers
  },
})

export default baseQuery

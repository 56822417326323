import { addPropIf, isDiff, isPresent, Obj, isSame } from './utils'

type Props = {
  url: string
  payload?: Obj
  contentType?: string
  headers?: Record<string, string>
  method?: string
}

let fetcher = async <T>({
  url,
  payload,
  contentType = 'application/json',
  headers,
  method = 'GET',
}: Props): Promise<
  { error: null; data: T } | { error: any; data: null }
> =>
  await fetch(url, {
    method,
    ...addPropIf(
      isDiff(contentType, 'multipart/form-data'),
      'headers',
      {
        'Content-Type': contentType,
        ...headers,
      }
    ),
    ...addPropIf(
      isPresent(payload),
      'body',
      isSame(contentType, 'application/json')
        ? JSON.stringify(payload)
        : payload
    ),
    // credentials: 'include',
    // redirect: 'follow',
  }).then(async (response) => {
    let error
    if (!response.ok) {
      error = response.statusText
      return { error, data: null }
    } else {
      let data
      try {
        data = await response.json()
      } catch (_) {
        error = `Failed to parse json from ${url}`
      }

      return { error: null, data: data.data }
    }
  })

export default fetcher

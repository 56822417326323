import { BASE_URL } from 'config/constants'
import { IGetCitiesResult, IMenuItem } from 'store/api'
import fetcher from './fetcher'
import { isNonEmptyString, isPresent, toPairs } from './utils'

export let getMenus = async (lng: string, city?: string | null) => {
  let cities: {
    data: {
      cities: IGetCitiesResult[]
    } | null
  } = isPresent(city)
    ? { data: { cities: [] } }
    : await fetcher({
        url: `${BASE_URL}/v1/cities/indexnew`,
        headers: { lng },
      })

  let getMenusResult = await fetcher({
    url: `${BASE_URL}/v1/content/menus`,
    headers: {
      lng,
      city: city ?? cities?.data?.cities[0]?.code ?? '',
    },
  })

  return getMenusResult.error
    ? {}
    : toPairs(
        getMenusResult.data as Record<string, IMenuItem[]>
      ).reduce((acc, [name, menus]) => {
        acc[name] = menus
          .filter((x) => isNonEmptyString(x.path))
          .map((x) => ({
            ...x,
            path: x.path.startsWith('/')
              ? x.path
              : x.path.startsWith('http')
              ? x.path
              : `/${x.path}`,
          }))
        return acc
      }, {} as Record<string, IMenuItem[]>)
}

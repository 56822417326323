import {
  createApi,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { createOrderSchema } from 'components/checkout/CheckoutView'
import { partnerSchema } from 'components/partners/PartnerForm'
import { IProductDetail } from 'components/product/ProductCard/types'
import { serviceSchema } from 'components/services/ServiceForm'
import { BASE_URL } from 'config/constants'
import fetcher from 'lib/fetcher'
import { getAllProducts } from 'lib/getAllProducts'
import { getMenus } from 'lib/getMenus'
import { prepareCoords } from 'lib/misc'
import {
  deepEq,
  isAbsent,
  isDiff,
  isEmptyArray,
  isEmptyObj,
  isEmptyString,
  isNonEmptyString,
  isPresent,
  isSame,
  omit,
  pick,
  toPairs,
} from 'lib/utils'
import { AppState } from 'store'
import { setNearest } from 'store/checkout'
import { z } from 'zod'
import {
  IAction,
  IActonItem,
  IAddToCartPayload,
  IAddToCartResult,
  IBanner,
  ICategory,
  ICheckCodeResult,
  IEntitySuggestion,
  IGetAboutResult,
  IGetCartResult,
  IGetCitiesResult,
  IGetOrder,
  IGetUserInfo,
  IMenuItem,
  IPage,
  IProduct,
  IProfile,
  IRegisterWithCodeResult,
  ISiteInfo,
  ISuggestion,
  ITooltipInfo,
  type IGetCartIntervalsResult,
  type ISbpPaymentResult,
  type ISbpStatusResult,
} from '.'
import { IAuthState, setAnonToken, setUserToken } from '../auth'
import baseQuery from './baseQuery'

let api = createApi({
  baseQuery,
  tagTypes: ['Cart', 'User', 'Orders', 'Intervals'],
  endpoints: (builder) => ({
    getCities: builder.query<
      IGetCitiesResult[],
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/cities/indexnew',
        headers: { lng },
      }),
      transformResponse: (response: {
        data: {
          cities: IGetCitiesResult[]
        }
      }) =>
        response.data.cities.map((x) => ({
          ...x,
          code: x.code,
          name: x.name,
        })),
    }),

    getAnonToken: builder.query<
      string,
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/auth/anontoken',
        headers: { lng },
      }),
      transformResponse: (response: { data: { token: string } }) =>
        response.data.token,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          let newAnonToken = (await queryFulfilled).data
          isPresent(newAnonToken) &&
            dispatch(setAnonToken(newAnonToken))
          return
        } catch {}
      },
    }),

    getTooltips: builder.query<
      ITooltipInfo[],
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/content/production',
        headers: { lng },
      }),
      transformResponse: (response: { data: ITooltipInfo[] }) =>
        response.data,
    }),

    getBanners: builder.query<
      { banners: IBanner[]; other_banners: IBanner[] },
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/cities/bannersv2',
        headers: { lng },
      }),
      transformResponse: (res: {
        data: { banners: IBanner[]; other_banners: IBanner[] }
      }) => pick(['banners', 'other_banners'], res.data),
    }),

    getCategoryList: builder.query<
      ICategory[],
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/categories/getall',
        headers: { lng },
      }),
      transformResponse: (res: {
        data: { categories: ICategory[] }
      }) => res.data.categories,
    }),

    getProductList: builder.query<
      {
        products: Record<string, IProduct[]>
        qty: Record<NonNullable<IAuthState['city']>, number>
      },
      NonNullable<IAuthState['lng']>
    >({
      async queryFn(lng) {
        let result = await getAllProducts(lng)

        return isPresent(result.products)
          ? { data: result }
          : {
              error: {
                status: 'CUSTOM_ERROR',
                data: undefined,
                error: 'Error fetching products',
              } as FetchBaseQueryError,
            }
      },
    }),

    getProductDetail: builder.query<
      IProductDetail,
      {
        lng: NonNullable<IAuthState['lng']>
        id: number
      }
    >({
      query: ({ lng, id }) => ({
        url: `/v1/products/get/${id}`,
        headers: { lng },
      }),
      transformResponse: (response: { data: IProductDetail }) =>
        response.data,
    }),

    getLocation: builder.query<
      ISuggestion,
      {
        lng: NonNullable<IAuthState['lng']>
        coords: [number, number]
      }
    >({
      query: ({ lng, coords }) => ({
        url: `/v1/address/geolocate/?lat=${coords[0]}&lng=${coords[1]}`,
        headers: { lng },
      }),
      transformResponse: (response: {
        data: { suggestions: ISuggestion[] }
      }) => response.data.suggestions[0]!,
    }),

    getAddressSuggestions: builder.mutation<
      ISuggestion[],
      { q: string; lng: NonNullable<IAuthState['lng']> }
    >({
      query: ({ q, lng }) => ({
        url: `/v1/address/suggestaddress?query=${q}`,
        headers: { lng },
      }),
      transformResponse: (response: {
        data: { list: { suggestions: ISuggestion[] } }
      }) => response.data.list.suggestions,
    }),

    getEntitySuggestions: builder.mutation<
      IEntitySuggestion[],
      {
        q: string
        token: string
      }
    >({
      query: ({ q, token }) => ({
        url: `/v1/cart/suggestparty?query=${q}&anonymouse-token=${token}`,
      }),
      transformResponse: (response: {
        data: { suggestions: IEntitySuggestion[] }
      }) => response.data.suggestions,
    }),

    // setDeliveryType: builder.mutation<
    //   void,
    //   {
    //     type: 'delivery' | 'pickup'
    //     lng: NonNullable<IAuthState['lng']>
    //   }
    // >({
    //   query: ({ type, lng }) => {
    //     let formData = new FormData()
    //     formData.append('type', type)
    //     return {
    //       url: '/v1/cart/setdelivery/',
    //       method: 'POST',
    //       body: formData,
    //       headers: { lng },
    //     }
    //   },
    //   invalidatesTags: ['Cart'],
    //   async onQueryStarted(payload, { dispatch, queryFulfilled }) {
    //     let { type, lng } = payload

    //     let result = dispatch(
    //       api.util.updateQueryData('getCart', lng, (draft) => {
    //         draft.data.cart.delivery_type = type
    //         return draft
    //       })
    //     )

    //     try {
    //       await queryFulfilled
    //     } catch {
    //       queryFulfilled.catch(result.undo)
    //     }
    //   },
    // }),

    setAddress: builder.mutation<
      void,
      {
        address: {
          value: ISuggestion['value']
          profile: Pick<
            ISuggestion['profile'],
            'house' | 'street' | 'city'
          >
        }
        lng: NonNullable<IAuthState['lng']>
        city: NonNullable<IAuthState['city']>
        defaultAddress?: boolean
      }
    >({
      query: ({
        address: { value, profile },
        lng,
        city,
        defaultAddress,
      }) => {
        let formData = new FormData()
        formData.append('address[value]', value)
        formData.append('address[data][house]', profile.house)
        formData.append('address[data][street]', profile.street)
        formData.append('address[data][city]', profile.city)
        formData.append('address[data][address]', value)
        formData.append('address[data][first_name]', '')
        formData.append('address[data][email]', '')
        formData.append('address[data][phone_code]', '')
        formData.append('address[data][phone_number]', '')
        formData.append('address[data][phone]', '')
        formData.append('address[data][drive]', '')
        formData.append('address[data][intercom]', '')
        formData.append('address[data][comments]', '')
        formData.append('address[data][room]', '')
        formData.append('address[data][floor]', '')
        formData.append('address[data][metro]', '')
        formData.append('address[data][is_main]', 'false')
        formData.append('address[data][id]', '')
        formData.append('address[data][profile_name]', '')
        formData.append(
          'address[data][meta]',
          defaultAddress ? 'default_address' : ''
        )

        return {
          url: '/v1/address/setaddress/',
          method: 'POST',
          body: formData,
          headers: { lng, city },
        }
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        let {
          address: { value, profile },
          lng,
          defaultAddress,
        } = payload

        let result = dispatch(
          api.util.updateQueryData('getCart', lng, (draft) => {
            draft.data.cart.address = {
              ...draft.data.cart.address,
              house: profile.house,
              street: profile.street,
              city: profile.city,
              metro: '',
              coords: {
                ...(draft.data.cart.address?.coords ?? {
                  error: '',
                  found: true,
                  coords: '',
                }),
                address: value,
              },
              meta: defaultAddress ? 'default_address' : '',
            }
            return draft
          })
        )

        try {
          await queryFulfilled
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    addAddress: builder.mutation<
      IProfile,
      {
        address: Omit<Partial<IProfile>, 'coords'> & {
          value: ISuggestion['value']
          phone: IGetUserInfo['phone']
          coords: number[]
        }
        user: { email: string; first_name?: string }
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      async queryFn(
        {
          address: {
            city,
            house,
            phone,
            street,
            value,
            comments,
            drive,
            floor,
            intercom,
            room,
            metro,
            coords,
            profile_name,
          },
          user: { email, first_name },
          lng,
        },
        _,
        _extraOptions,
        fetchWithBQ
      ) {
        let _address = {
          address: value,
          city,
          comments: comments ?? null,
          coords,
          drive: drive ?? null,
          email,
          first_name: first_name,
          //firstName || localStorage?.getItem('firstName') || email,
          floor: floor ?? null,
          house,
          intercom: intercom ?? null,
          is_main: true,
          meta: null,
          metro: metro ?? null,
          phone,
          phone_code: isPresent(phone) ? phone.slice(0, 3) : '',
          phone_number: isPresent(phone) ? phone.slice(3) : '',
          profile_name: profile_name || 'Мой адрес',
          room: room ?? null,
          street,
          id: null,
        }

        let formData = new FormData()
        formData.append('address', JSON.stringify(_address))

        let result: any = await fetchWithBQ({
          url: '/v1/auth/addaddress/',
          method: 'POST',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error adding address',
            } as FetchBaseQueryError,
          }

        return {
          data: result.data.data.address as IProfile,
        }
      },
      invalidatesTags: ['User'],
      async onQueryStarted(
        { address, lng },
        { dispatch, queryFulfilled }
      ) {
        let result = isDiff(address.id, 'tmp_reorder')
          ? dispatch(
              api.util.updateQueryData(
                'getUserInfo',
                lng,
                (draft) => {
                  draft.data.user.profiles = [
                    {
                      id: 'tmp_add',
                      profile_name:
                        address.profile_name || 'Мой адрес',
                      address: address.value ?? '',
                    } as any,
                    ...(draft.data.user.profiles ?? []),
                  ]

                  return draft
                }
              )
            )
          : null

        try {
          await queryFulfilled
        } catch {
          isPresent(result) && queryFulfilled.catch(result.undo)
        }
      },
    }),

    updateAddress: builder.mutation<
      void,
      {
        address: IProfile
        lng: NonNullable<IAuthState['lng']>
        mainOnly?: boolean
      }
    >({
      query: ({ address, lng }) => {
        let formData = new FormData()
        formData.append(
          'address',
          JSON.stringify({
            ...address,
            phone: `${address.phone_code}${address.phone_number}`,
          })
        )

        return {
          url: `/v1/auth/updateaddress/${address.id}`,
          method: 'POST',
          body: formData,
          headers: { lng },
        }
      },
      async onQueryStarted(
        { address, lng, mainOnly = false },
        { dispatch, queryFulfilled }
      ) {
        let result = dispatch(
          api.util.updateQueryData('getUserInfo', lng, (draft) => {
            if (address.is_main || mainOnly) {
              let prefMainProfileIdx =
                draft.data.user.profiles?.findIndex((x) => x.is_main)

              if (isDiff(prefMainProfileIdx, -1)) {
                draft.data.user.profiles![
                  prefMainProfileIdx!
                ]!.is_main = false
              }
            }

            let currentProfileIdx =
              draft.data.user.profiles?.findIndex((x) =>
                isSame(x.id, address.id)
              )

            if (isDiff(currentProfileIdx, -1)) {
              draft.data.user.profiles![currentProfileIdx!] = address
            }

            return draft
          })
        )

        try {
          await queryFulfilled
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    removeAddress: builder.mutation<
      void,
      { lng: NonNullable<IAuthState['lng']>; id: string }
    >({
      query: ({ lng, id }) => ({
        method: 'POST',
        url: `/v1/auth/removeaddress/${id}`,
        headers: { lng },
      }),
      async onQueryStarted(
        { id, lng },
        { dispatch, queryFulfilled }
      ) {
        let result = dispatch(
          api.util.updateQueryData('getUserInfo', lng, (draft) => {
            draft.data.user.profiles =
              draft.data.user.profiles?.filter((x) =>
                isDiff(x.id, id)
              ) ?? []

            return draft
          })
        )

        try {
          await queryFulfilled
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    getCart: builder.query<
      { data: { cart: IGetCartResult } },
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({ url: '/v1/cart/cart', headers: { lng } }),
      providesTags: ['Cart'],
    }),

    getCartIntervals: builder.query<
      { data: { data: IGetCartIntervalsResult } },
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/cart/intervals',
        headers: { lng },
      }),
      providesTags: ['Cart', 'Intervals'],
    }),

    clearCart: builder.mutation<void, NonNullable<IAuthState['lng']>>(
      {
        query: (lng) => ({
          method: 'POST',
          url: '/v1/cart/clear',
          headers: { lng },
        }),
        async onQueryStarted(lng, { dispatch, queryFulfilled }) {
          let result = dispatch(
            api.util.updateQueryData('getCart', lng, (draft) => {
              draft.data.cart.items = []
              return draft
            })
          )

          try {
            await queryFulfilled
          } catch {
            queryFulfilled.catch(result.undo)
          }
        },
      }
    ),

    addCartItem: builder.mutation<
      { data: { item: IAddToCartResult } },
      IAddToCartPayload & {
        lng: NonNullable<IAuthState['lng']>
        price: number
        variation_id: number
        ingredients?: number[][]
        type: 'simple' | 'variable'
      }
    >({
      invalidatesTags: ['Cart'],
      async queryFn(
        { id, quantity, variation_id, ingredients, type, lng },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) {
        let formData = new FormData()
        formData.append(
          'item',
          JSON.stringify({
            product: { id, variation_id, type },
            ingredients,
            quantity,
          })
        )

        let addCartItemResult = await fetchWithBQ({
          method: 'POST',
          url: '/v1/cart/add',
          body: formData,
          headers: { lng },
        })

        return isPresent((addCartItemResult as any)?.data?.data?.item)
          ? {
              data: addCartItemResult.data as {
                data: { item: IAddToCartResult }
              },
            }
          : {
              error: {
                status: 'CUSTOM_ERROR',
                data: undefined,
                error: 'Error adding to cart',
              } as FetchBaseQueryError,
            }
      },
    }),

    updateCartItem: builder.mutation<
      { data: { item: IAddToCartResult } },
      IAddToCartPayload & {
        key: IGetCartResult['items'][number]['key']
        variation_id: number
        lng: NonNullable<IAuthState['lng']>
        type: string
      }
    >({
      invalidatesTags: ['Cart'],
      queryFn: async (
        { key, id, variation_id, quantity, lng, type, ingredients },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('key', key)
        formData.append(
          'item',
          JSON.stringify({
            product: { id, variation_id, type },
            ingredients,
            quantity,
          })
        )

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/cart/change',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error updating cart',
            } as FetchBaseQueryError,
          }

        return isPresent(result?.data?.data?.item)
          ? {
              data: result.data as {
                data: { item: IAddToCartResult }
              },
            }
          : {
              error: {
                status: 'CUSTOM_ERROR',
                data: undefined,
                error: 'Error updating cart',
              } as FetchBaseQueryError,
            }
      },
    }),

    removeCartItem: builder.mutation<
      void,
      {
        key: IGetCartResult['items'][number]['key']
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      query: ({ key, lng }) => {
        let formData = new FormData()
        formData.append('key', key)

        return {
          method: 'POST',
          url: '/v1/cart/remove',
          body: formData,
          headers: { lng },
        }
      },
      async onQueryStarted(
        { key, lng },
        { dispatch, queryFulfilled }
      ) {
        let result = dispatch(
          api.util.updateQueryData('getCart', lng, (draft) => {
            draft.data.cart.items = draft.data.cart.items.filter(
              (x) => isDiff(x.key, key)
            )
            return draft
          })
        )

        try {
          await queryFulfilled
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
      invalidatesTags: ['Cart'],
    }),

    addCoupon: builder.mutation<
      { data: { errors: string[] } },
      { coupon: string; lng: NonNullable<IAuthState['lng']> }
    >({
      query: ({ coupon, lng }) => {
        let formData = new FormData()
        formData.append('coupon', coupon)
        formData.append('action', 'add')

        return {
          method: 'POST',
          url: '/v1/cart/code',
          body: formData,
          headers: { lng },
        }
      },
      invalidatesTags: ['Cart'],
    }),

    removeCoupon: builder.mutation<
      { data: { errors: string[] } },
      { coupon: string; lng: NonNullable<IAuthState['lng']> }
    >({
      query: ({ coupon, lng }) => {
        let formData = new FormData()
        formData.append('coupon', coupon)
        formData.append('action', 'delete')

        return {
          method: 'POST',
          url: '/v1/cart/code',
          body: formData,
          headers: { lng },
        }
      },
      invalidatesTags: ['Cart'],
    }),

    getDate: builder.query<
      { date: string },
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/auth/getdate',
        headers: { lng },
      }),
    }),

    sendSms: builder.mutation<
      void,
      {
        phone: string
        lng: NonNullable<IAuthState['lng']>
        smstoken: string
      }
    >({
      queryFn: async (
        { phone, lng, smstoken },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('phone', phone)

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/auth/sendSms',
          body: formData,
          headers: { lng, smstoken },
        })
        if (result.error)
          return {
            error: result.error.error,
          }

        return isSame(result?.data?.status, 'ok')
          ? { data: result.data }
          : { error: result?.data?.msg }
      },
    }),

    checkCode: builder.mutation<
      { data: ICheckCodeResult },
      {
        phone: string
        code: string
        useUserToken?: boolean
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      queryFn: async (
        { phone, code, lng },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('phone', phone)
        formData.append('code', code)

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/auth/checkCode',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error checking code',
            } as FetchBaseQueryError,
          }

        return isSame(result?.data?.status, 'success')
          ? { data: result.data as { data: ICheckCodeResult } }
          : { error: result?.data?.msg }
      },
      invalidatesTags: ['User'],
      async onQueryStarted(
        { useUserToken = true },
        { dispatch, queryFulfilled }
      ) {
        try {
          let newUserToken = (await queryFulfilled).data.data.token
          useUserToken &&
            isPresent(newUserToken) &&
            dispatch(setUserToken(newUserToken))
          return
        } catch {}
      },
    }),

    registerWithCode: builder.mutation<
      { data: IRegisterWithCodeResult },
      {
        phone: string
        code: string
        first_name: string
        email: string
        phone_notify: boolean
        email_notify: boolean
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      queryFn: async (
        {
          phone,
          code,
          first_name,
          email,
          phone_notify,
          email_notify,
          lng,
        },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('phone', phone)
        formData.append('code', code)
        formData.append('first_name', first_name)
        formData.append('email', email)
        formData.append('phone_notify', `${phone_notify}`)
        formData.append('email_notify', `${email_notify}`)

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/auth/registerWithCode',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error registering with code',
            } as FetchBaseQueryError,
          }

        return isDiff(result?.data?.status, 'error')
          ? { data: result.data as { data: IRegisterWithCodeResult } }
          : { error: result?.data?.msg }
      },
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          let newUserToken = (await queryFulfilled).data.data.token
          if (isPresent(newUserToken)) {
            dispatch(setUserToken(newUserToken))
            //localStorage.setItem('firstName', firstName)
          }
          return
        } catch {}
      },
    }),

    confirmEmail: builder.mutation<
      string,
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/auth/confirm',
        headers: { lng },
        method: 'POST',
      }),
      transformResponse: (response: { data: { msg: string } }) =>
        response.data.msg,
    }),

    verifyEmail: builder.mutation<
      { data: { status: string } },
      {
        lng: NonNullable<IAuthState['lng']>
        email: string
        token: string
      }
    >({
      queryFn: async (
        { email, token, lng },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('email', email)
        formData.append('token', token)

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/auth/verifyemail',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error verifying email',
            } as FetchBaseQueryError,
          }

        return isSame(result?.data?.data?.status, 'success') &&
          isAbsent(result?.data?.data?.errors[0])
          ? {
              data: result.data as {
                data: { status: string }
              },
            }
          : { error: result?.data?.data?.errors[0] }
      },
    }),

    getUserInfo: builder.query<
      { data: { user: IGetUserInfo } },
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/auth/userinfo',
        headers: { lng },
      }),
      providesTags: ['User'],
    }),

    logout: builder.mutation<void, NonNullable<IAuthState['lng']>>({
      query: (lng) => ({
        method: 'POST',
        url: '/v1/auth/logout',
        headers: { lng },
      }),
    }),

    deleteProfile: builder.mutation<
      void,
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        method: 'POST',
        url: '/v1/auth/deleteprofile',
        headers: { lng },
      }),
    }),

    saveSettings: builder.mutation<
      void,
      {
        phone: string
        email: string
        phoneNotify: boolean
        emailNotify: boolean
        birth_date: string
        first_name: string
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      invalidatesTags: ['User'],
      queryFn: async (
        {
          phone,
          email,
          phoneNotify,
          emailNotify,
          birth_date,
          first_name,
          lng,
        },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('phone_notify', `${phoneNotify}`)
        formData.append('email_notify', `${emailNotify}`)
        formData.append('birth_date', birth_date)
        formData.append('first_name', first_name)

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/auth/settings',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error saving settings',
            } as FetchBaseQueryError,
          }

        return isEmptyArray(result?.data?.data.errors)
          ? { data: result.data }
          : { error: result?.data?.data.msg }
      },
      async onQueryStarted(
        {
          email,
          phone,
          phoneNotify,
          emailNotify,
          birth_date,
          first_name,
          lng,
        },
        { dispatch, queryFulfilled }
      ) {
        let result = dispatch(
          api.util.updateQueryData('getUserInfo', lng, (draft) => {
            draft.data.user = {
              ...draft.data.user,
              phone,
              email,
              birth_date,
              first_name,
              settings: {
                ...draft.data.user.settings,
                phone_notify: Number(phoneNotify),
                email_notify: Number(emailNotify),
              },
            }
            return draft
          })
        )

        try {
          await queryFulfilled
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    getOrders: builder.query<
      { data: { orders: IGetOrder[] } },
      NonNullable<IAuthState['lng']>
    >({
      providesTags: ['Orders'],
      query: (lng) => ({
        url: '/v1/orders/list',
        headers: { lng },
      }),
    }),

    getOrder: builder.query<
      IGetOrder,
      { lng: NonNullable<IAuthState['lng']>; id: string }
    >({
      query: ({ lng, id }) => ({
        url: `/v1/orders/info/${id}`,
        headers: { lng },
      }),
      transformResponse: (response: { data: { order: IGetOrder } }) =>
        response.data.order,
    }),

    createOrder: builder.mutation<
      { data: { order: { number: number; id: number } } },
      {
        payload: z.infer<typeof createOrderSchema>
        profileId?: string
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      invalidatesTags: ['Orders', 'Intervals'],
      async queryFn(
        { payload, lng, profileId },
        { getState },
        _extraOptions,
        fetchWithBQ
      ) {
        let cart = (
          (getState() as AppState).api.queries[
            `getCart("${lng}")`
          ] as {
            data: { data: { cart: IGetCartResult } }
          }
        ).data.data.cart

        let user = (
          (getState() as AppState).api.queries[
            `getUserInfo("${lng}")`
          ] as {
            data: { data: { user: IGetUserInfo } }
          }
        ).data.data.user

        let prevProfile = user.profiles?.find((x) =>
          isSame(x.id, profileId)
        )

        let profilePayloadPart = {
          room: payload.room || null,
          drive: payload.drive || null,
          floor: payload.floor || null,
          comments: payload.comments || null,
          intercom: payload.intercom || null,
          meta: null,
          coords: [],
          referrer: '',
          entity_name: payload.entity_name ?? '',
          entity_address: payload.entity_address ?? '',
          inn: payload.inn ?? '',
          kpp: payload.kpp ?? '',
        }

        let profile = isPresent(prevProfile)
          ? {
              ...prevProfile,
              ...profilePayloadPart,
              email: user.email,
              first_name: user.first_name || user.email,
              phone_code: user.phone.slice(0, 3),
              phone_number: user.phone.slice(3),
              phone: user.phone,
            }
          : {
              ...profilePayloadPart,
              id: null,
              city: cart.address!.city,
              email: user.email,
              house: cart.address!.house,
              metro: cart.address!.metro,
              street: cart.address!.street,
              address: cart.address!.coords.address,
              is_main: true,
              first_name: user.first_name || user.email,
              phone_code: user.phone.slice(0, 3),
              phone_number: user.phone.slice(3),
              profile_name: 'Мой адрес',
              phone: user.phone,
            }

        let formData = new FormData()
        formData.append('profile', JSON.stringify(profile))
        formData.append('payment', cart.payment_type)

        let createOrderResult: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/cart/order',
          body: formData,
          headers: { lng },
        })
        if (createOrderResult.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error creating order',
            } as FetchBaseQueryError,
          }

        return isEmptyArray(createOrderResult?.data?.data.errors)
          ? {
              data: createOrderResult.data as {
                data: { order: { number: number; id: number } }
              },
            }
          : { error: createOrderResult?.data?.data.errors[0] }
      },
      async onQueryStarted({ lng }, { queryFulfilled }) {
        try {
          await queryFulfilled
          api.util.updateQueryData('getCart', lng, (draft) => {
            draft.data.cart.items = []
            return draft
          })
        } catch {}
      },
    }),

    reOrder: builder.mutation<
      { data: { order: IGetOrder } },
      {
        lng: NonNullable<IAuthState['lng']>
        orderId: number
        profile: IProfile
      }
    >({
      invalidatesTags: ['Orders'],
      query: ({ lng, orderId }) => ({
        url: `/v1/cart/reorder/${orderId}`,
        headers: { lng },
      }),
      async onQueryStarted(
        { lng, profile },
        { dispatch, queryFulfilled, getState }
      ) {
        let result = dispatch(
          api.util.updateQueryData('getUserInfo', lng, (draft) => {
            let mainProfileIdx = draft.data.user.profiles?.findIndex(
              (x) => isSame(x.is_main, true)
            )

            if (
              isPresent(mainProfileIdx) &&
              isDiff(mainProfileIdx, -1)
            ) {
              draft.data.user.profiles![mainProfileIdx]!.is_main =
                false
            }

            let currentProfileIdx =
              draft.data.user.profiles?.findIndex(
                (x) =>
                  (isPresent(profile.id) &&
                    isSame(x.id, profile.id)) ||
                  isSame(x.address, profile.address)
              )

            isPresent(currentProfileIdx) &&
            isDiff(currentProfileIdx, -1)
              ? (draft.data.user.profiles![
                  currentProfileIdx
                ]!.is_main = true)
              : (draft.data.user.profiles = [
                  {
                    ...profile,
                    is_main: true,
                    id: profile.id ?? 'tmp_reorder',
                    profile_name: 'Мой адрес',
                  },
                  ...(draft.data.user.profiles ?? []),
                ])

            return draft
          })
        )

        try {
          let orderProfile = (await queryFulfilled).data.data.order
            .profile

          if (isPresent(orderProfile.id)) {
            await dispatch(
              api.endpoints.updateAddress.initiate({
                address: { ...orderProfile, is_main: true },
                lng,
              })
            )
          } else {
            let profiles =
              (
                getState().api.queries[`getUserInfo("${lng}")`]
                  ?.data as {
                  data: { user: IGetUserInfo | undefined }
                }
              )?.data?.user?.profiles ?? []

            let matchedProfile = profiles
              .filter((x) => isDiff(x.id, 'tmp_reorder'))
              .find((x) =>
                deepEq(
                  pick(['address', 'floor', 'drive', 'room'], x),
                  pick(
                    ['address', 'floor', 'drive', 'room'],
                    orderProfile
                  )
                )
              )

            isPresent(matchedProfile)
              ? await dispatch(
                  api.endpoints.updateAddress.initiate({
                    address: { ...matchedProfile, is_main: true },
                    lng,
                  })
                )
              : await dispatch(
                  api.endpoints.addAddress.initiate({
                    address: {
                      ...orderProfile,
                      value: orderProfile.address,
                      coords: prepareCoords(
                        (orderProfile.coords as { coords: string })
                          .coords
                      ),
                      id: 'tmp_reorder',
                      profile_name: 'Мой адрес',
                    },
                    user: { email: orderProfile.email },
                    lng,
                  })
                )
          }
          return
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    setPaymentMethod: builder.mutation<
      void,
      { type: string; lng: NonNullable<IAuthState['lng']> }
    >({
      invalidatesTags: ['Cart'],
      queryFn: async (
        { type, lng },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        let formData = new FormData()
        formData.append('type', type)

        let result: any = await fetchWithBQ({
          method: 'POST',
          url: '/v1/cart/paymenttype',
          body: formData,
          headers: { lng },
        })
        if (result.error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: 'Error setting payment method',
            } as FetchBaseQueryError,
          }

        return isSame(result?.data?.status, 'success')
          ? { data: result.data }
          : { error: result?.data?.msg }
      },
      async onQueryStarted(
        { type, lng },
        { dispatch, queryFulfilled }
      ) {
        let result = dispatch(
          api.util.updateQueryData('getCart', lng, (draft) => {
            draft.data.cart.payment_type = type
            return draft
          })
        )

        try {
          await queryFulfilled
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    setDeliveryDate: builder.mutation<
      {
        status: string
        code: number
        message: string
        data: {
          datein: boolean
          first: string
          second: string
          nearest: string
          errors: any[]
        }
      },
      { date: string; lng: NonNullable<IAuthState['lng']> }
    >({
      invalidatesTags: ['Cart'],
      query: ({ lng, date }) => {
        let formData = new FormData()
        formData.append('date', date)

        return {
          url: '/v1/cart/actiondates',
          headers: { lng },
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted(
        { lng, date },
        { dispatch, queryFulfilled }
      ) {
        let [_date, time] = date.split(' ')

        let newDate = _date

        let result = dispatch(
          api.util.updateQueryData('getCart', lng, (draft) => {
            if (isNonEmptyString(newDate)) {
              draft.data.cart.action_date = `${newDate} ${time}`
            } else {
              delete draft.data.cart.action_date
            }

            return draft
          })
        )

        try {
          let deliveryData = await queryFulfilled
          let nearest = deliveryData.data.data.nearest

          if (isEmptyString(nearest)) {
            dispatch(setNearest(''))
          } else {
            let [date, time] = nearest.split(' ')
            dispatch(
              setNearest(
                `${date!.split('.').reverse().join('/')} ${time}`
              )
            )
          }
        } catch {
          queryFulfilled.catch(result.undo)
        }
      },
    }),

    sendServiceForm: builder.mutation<
      void,
      {
        payload: z.infer<typeof serviceSchema>
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      query: ({ payload, lng }) => {
        let formData = new FormData()
        toPairs(omit(['agreed'], payload)).forEach(([key, value]) =>
          formData.append(key, value)
        )

        return {
          url: '/v1/service/order',
          headers: { lng },
          method: 'POST',
          body: formData,
        }
      },
    }),

    sendPartnerForm: builder.mutation<
      void,
      {
        payload: z.infer<typeof partnerSchema>
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      query: ({ payload, lng }) => {
        let formData = new FormData()
        toPairs(omit(['agreed'], payload)).forEach(([key, value]) =>
          formData.append(key, value)
        )

        return {
          url: '/v1/content/partner',
          headers: { lng },
          method: 'POST',
          body: formData,
        }
      },
    }),

    updateActions: builder.mutation<
      void,
      {
        actions: number[]
        added: number[]
        removed: number[]
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      invalidatesTags: ['Cart'],
      query: ({ actions, added, removed, lng }) => {
        let formData = new FormData()
        formData.append('actions', JSON.stringify(actions))
        formData.append('added', JSON.stringify(added))
        formData.append('removed', JSON.stringify(removed))

        return {
          url: '/v1/cart/action',
          headers: { lng },
          method: 'POST',
          body: formData,
        }
      },
    }),

    getCartActions: builder.query<
      { data: { actions: IAction[] } },
      { lng: NonNullable<IAuthState['lng']>; actionList: number[] }
    >({
      query: ({ lng, actionList }) => {
        let formData = new FormData()
        formData.append('actions', JSON.stringify(actionList))

        return {
          url: '/v1/cart/loadaction',
          headers: { lng },
          method: 'POST',
          body: formData,
        }
      },
    }),

    selectGifts: builder.mutation<
      void,
      {
        gifts: Record<number, IActonItem[]>
        lng: NonNullable<IAuthState['lng']>
      }
    >({
      query: ({ gifts, lng }) => {
        let formData = new FormData()
        formData.append('selected', JSON.stringify(gifts))

        return {
          method: 'POST',
          url: '/v1/cart/selectaction',
          body: formData,
          headers: { lng },
        }
      },
      invalidatesTags: ['Cart'],
    }),

    // getInstaPosts: builder.query<IInstaData, void>({
    //   queryFn: async () => {
    //     let res = await fetcher<IInstaData>({
    //       url: `/api/getInstaPosts`,
    //     })

    //     return isPresent(res.data)
    //       ? { data: res.data }
    //       : { error: res.error }
    //   },
    // }),

    getMenus: builder.query<
      Record<string, IMenuItem[]>,
      NonNullable<IAuthState['lng']>
    >({
      async queryFn(lng) {
        let city = localStorage?.getItem('city')
        let menus = await getMenus(lng, city)

        return isEmptyObj(menus)
          ? {
              error: {
                status: 'CUSTOM_ERROR',
                data: undefined,
                error: 'Error fetching menus',
              } as FetchBaseQueryError,
            }
          : { data: menus }
      },
    }),

    getPage: builder.query<
      IPage,
      { lng: NonNullable<IAuthState['lng']>; slug: string }
    >({
      query: ({ lng, slug }) => ({
        url: `/v1/content/page/${slug}`,
        headers: { lng },
      }),
      transformResponse: (response: { data: { page: IPage } }) =>
        response.data.page,
    }),

    getAbout: builder.query<
      IGetAboutResult[],
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => ({
        url: '/v1/content/about',
        headers: { lng },
      }),
      transformResponse: (response: { data: IGetAboutResult[] }) =>
        response.data,
    }),

    setUniqueId: builder.mutation<
      any,
      NonNullable<IAuthState['lng']>
    >({
      query: (lng) => {
        let data = new FormData()
        data.append('uniqueOldId', 'web')
        data.append('uniqueId', 'web')

        return {
          method: 'POST',
          url: '/v1/cart/setunique',
          body: data,
          headers: { lng },
        }
      },
      invalidatesTags: ['Cart'],
    }),

    getSiteInfo: builder.query({
      query: () => ({
        url: '/v1/content/appsettings',
      }),
      transformResponse: (response: { data: ISiteInfo }) => {
        return response.data
      },
    }),

    getSbpPaymentStatus: builder.query<
      ISbpStatusResult,
      { lng: NonNullable<IAuthState['lng']>; id: string }
    >({
      async queryFn({ lng, id }) {
        let result = await fetcher({
          url: `${BASE_URL}/v1/payment/sbpstatus?order_id=${id}`,
          headers: { lng },
        })

        return isPresent(result?.error)
          ? {
              error: {
                status: 'CUSTOM_ERROR',
                data: undefined,
                error: 'Error fetching payment status',
              } as FetchBaseQueryError,
            }
          : {
              data: (
                result as {
                  error: null
                  data: ISbpStatusResult
                }
              ).data,
            }
      },
    }),

    initSpbPayment: builder.query<
      ISbpPaymentResult,
      { lng: NonNullable<IAuthState['lng']>; id: string }
    >({
      async queryFn({ lng, id }) {
        let result = await fetcher({
          url: `${BASE_URL}/v1/payment/sbp?order_id=${id}`,
          headers: { lng },
        })

        return isPresent(result?.error)
          ? {
              error: {
                status: 'CUSTOM_ERROR',
                data: undefined,
                error: 'Error fetching payment data',
              } as FetchBaseQueryError,
            }
          : {
              data: (
                result as {
                  error: null
                  data: ISbpPaymentResult
                }
              ).data,
            }
      },
    }),
  }),
})

export default api

import api from './reducer'

export let {
  useGetAddressSuggestionsMutation,
  useSetAddressMutation,
  useGetCategoryListQuery,
  useLazyGetCategoryListQuery,
  useGetProductListQuery,
  useGetAnonTokenQuery,
  useGetEntitySuggestionsMutation,
  useGetCartQuery,
  useGetCartIntervalsQuery,
  useLazyGetMenusQuery,
  useGetPageQuery,
  useAddCartItemMutation,
  useUpdateCartItemMutation,
  useRemoveCartItemMutation,
  useAddCouponMutation,
  useRemoveCouponMutation,
  useGetBannersQuery,
  useLazyGetBannersQuery,
  useGetCitiesQuery,
  useLazyGetLocationQuery,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useSendSmsMutation,
  useCheckCodeMutation,
  useRegisterWithCodeMutation,
  useLazyGetCartQuery,
  useSetPaymentMethodMutation,
  useCreateOrderMutation,
  useAddAddressMutation,
  useGetOrdersQuery,
  useUpdateAddressMutation,
  useLogoutMutation,
  useRemoveAddressMutation,
  useGetOrderQuery,
  useReOrderMutation,
  useClearCartMutation,
  useLazyGetProductListQuery,
  useSaveSettingsMutation,
  useConfirmEmailMutation,
  useDeleteProfileMutation,
  useSetDeliveryDateMutation,
  useVerifyEmailMutation,
  useSendServiceFormMutation,
  useGetCartActionsQuery,
  useSelectGiftsMutation,
  useGetAboutQuery,
  useLazyGetAboutQuery,
  useGetProductDetailQuery,
  useSetUniqueIdMutation,
  useSendPartnerFormMutation,
  useGetSiteInfoQuery,
  useUpdateActionsMutation,
  useGetDateQuery,
  useLazyInitSpbPaymentQuery,
  useLazyGetSbpPaymentStatusQuery,
} = api

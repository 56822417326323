import { z } from 'zod'
import { isPresent } from './utils'

export const zodCustomErrorMap =
  (locale: string): z.ZodErrorMap =>
  (issue, ctx) => {
    const isRu = locale === 'ru'
    switch (issue.code) {
      case z.ZodIssueCode.invalid_string:
        if (issue.validation === 'email')
          return {
            message: isRu
              ? `Пожалуйста, введите корректный адрес электронной почты`
              : 'Please, enter correct email',
          }
        else
          return {
            message: isRu ? 'Некорректное значение' : 'Invalid value',
          }

      case z.ZodIssueCode.custom:
        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Пожалуйста, введите номер телефона'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Please, enter your phone number',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Пожалуйста, введите адрес'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Please, enter your phone address',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Пожалуйста, введите имя'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Please, enter your phone name',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Пожалуйста, введите промо-код'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Please, enter promo code',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Пожалуйста, введите код'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Please, enter code',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Это поле не должно быть пустым'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'This field must not be empty',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] ===
            'Строка должна содержать минимум 8 символов'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The string is too short (minimum 8 characters)',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Превышен лимит длины'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The string is too long',
          }
        if (
          isPresent(issue.params) &&
          issue.params['msg'] ===
            'Превышен лимит длины, максимум 64 символа'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The string is too long (more than 64 characters)',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] ===
            'Превышен лимит длины, максимум 256 символов'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The string is too long (more than 256 characters)',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] ===
            'Превышен лимит длины, максимум 65536 символов'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The string is too long (more than 65536 characters)',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] ===
            'Адрес электронной почты слишком длинный (более 64 символов)'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Email address is too long (more than 64 characters)',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] ===
            'Пожалуйста, введите адрес электронной почты'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'Please, enter your email',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Некорректный номер'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The phone number is incorrect',
          }

        if (
          isPresent(issue.params) &&
          issue.params['msg'] === 'Некорректный код'
        )
          return {
            message: isRu
              ? issue.params['msg']
              : 'The code is incorrect',
          }
        else
          return {
            message: isRu ? 'Некорректное значение' : 'Invalid value',
          }

      default:
        return { message: ctx.defaultError }
    }
  }

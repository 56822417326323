import clsx from 'clsx'
import { inProduction } from 'lib/misc'
import { isAbsent } from 'lib/utils'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCart, selectUser } from 'store/api'
import {
  selectAnonToken,
  selectCity,
  selectLng,
  selectUserToken,
} from 'store/auth'
import { selectHistory } from 'store/ui'

type ErrorFallbackProps = {
  error: Error & {
    statusCode?: number
    message?: string
    name?: string
  }
  resetErrorBoundary: (...args: Array<unknown>) => void
}

type ErrorComponentProps = {
  statusCode?: number
  message?: string
  stack?: string
  className?: string
}

export let ErrorComponent = ({
  message,
  stack,
  className = 'mt-3',
}: ErrorComponentProps) => {
  if (isAbsent(message)) return null

  return (
    <div
      className={clsx(
        'flex py-3 px-4 mt-4 bg-white border border-accent z-130',
        className
      )}
      style={{ boxShadow: '0 0 0 4px #fcf4f4 inset' }}
    >
      <svg
        className='flex-shrink-0 w-10 h-10 text-accent'
        viewBox='0 0 24 24'
        stroke='currentColor'
        strokeWidth={1.5}
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
        <line x1='12' y1='9' x2='12' y2='13'></line>
        <line x1='12' y1='17' x2='12.01' y2='17'></line>
      </svg>
      <div className='ml-3'>
        <h3 className='text-accent font-semibold leading-snug'>
          Произошла ошибка
        </h3>
        <div className='mt-1 text-sm leading-snug'>{message}</div>
        <div className='mt-1 text-sm leading-snug'>{stack}</div>
      </div>
    </div>
  )
}

export let RootErrorFallback = ({ error }: ErrorFallbackProps) => {
  let history = useSelector(selectHistory)
  let queryLng = useSelector(selectLng)
  let anonToken = useSelector(selectAnonToken)
  let userToken = useSelector(selectUserToken)
  let city = useSelector(selectCity)
  let cart = useSelector(selectCart(queryLng ?? 'ru'))
  let user = useSelector(selectUser(queryLng ?? 'ru'))

  useEffect(() => {
    inProduction &&
      fetch('/api/send-mail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: error.message || error.name,
          stack: error.stack,
          history,
          queryLng,
          anonToken,
          userToken,
          city,
          cart,
          user,
        }),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorComponent
      statusCode={error['statusCode'] || 400}
      message={error.message || error.name || ''}
      stack={error.stack}
      className='mx-4'
    />
  )
}

export default ErrorComponent

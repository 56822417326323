import { createAction, createReducer } from '@reduxjs/toolkit'
import { isServer } from 'lib/misc'
import { isDiff } from 'lib/utils'
import { AppState } from '.'

export type IUIState = {
  rem: number
  isSm: boolean
  isLg: boolean
  isMd: boolean,
  isXl: boolean,
  isBrowser: boolean
  history: string[]
  selectedGifts: (number | null)[]
  isSignInModalOpen: boolean
}

/*
 * Actions
 */

export let setIsSignInModalOpen = createAction<boolean>(
  'ui/setIsSignInModalOpen'
)

export let setContext =
  createAction<Pick<IUIState, 'rem'>>('ui/setContext')

export let setBreakpoints = createAction<
  Pick<IUIState, 'isLg' | 'isMd' | 'isSm' | 'isXl'>
>('ui/setBreakpoints')

export let setHistory = createAction<string>('ui/setHistory')

export let resetSelectedGifts = createAction('ui/resetSelectedGift')

export let setSelectedGift = createAction<[number, number]>(
  'ui/setSelectedGift'
)

export let setSelectedGiftLength = createAction<number>('ui/setSelectedGiftLength')

/*
 * Reducer
 */
let initialState: IUIState = {
  rem: 16,
  isSm: false,
  isLg: false,
  isMd: false,
  isXl: false,
  isBrowser: false,
  history: ['/', isServer ? '/' : window.location.pathname],
  selectedGifts: [null, null],
  isSignInModalOpen: false,
}

export let uiReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(setIsSignInModalOpen, (state, { payload }) => {
      state.isSignInModalOpen = payload
    })

    .addCase(setContext, (state, { payload }) => {
      state.isBrowser = true
      state.rem = payload.rem
    })

    .addCase(setBreakpoints, (state, { payload }) => {
      state.isXl = payload.isXl
      state.isLg = payload.isLg
      state.isMd = payload.isMd
      state.isSm = payload.isSm
    })

    .addCase(setHistory, (state, { payload }) => {
      if (isDiff(payload, state.history[1])) {
        state.history = [state.history[1] ?? '/', payload]
      }
    })

    .addCase(setSelectedGift, (state, { payload }) => {
      state.selectedGifts[payload[0]] = payload[1]
    })

    .addCase(setSelectedGiftLength, (state, { payload }) => {
      state.selectedGifts = new Array(payload).fill(null)
    })

    .addCase(resetSelectedGifts, (state) => {
      const arrLength = state.selectedGifts.length
      state.selectedGifts = new Array(arrLength).fill(null)
    })
})

/*
 * Selectors
 */
export let selectIsSignModalOpen = (state: AppState) =>
  state.ui.isSignInModalOpen
export let selectIsBrowser = (state: AppState) => state.ui.isBrowser
export let selectIsLg = (state: AppState) => state.ui.isLg
export let selectIsXl = (state: AppState) => state.ui.isXl
export let selectIsMd = (state: AppState) => state.ui.isMd
export let selectIsSm = (state: AppState) => state.ui.isSm
export let selectRem = (state: AppState) => state.ui.rem
export let selectHistory = (state: AppState) => state.ui.history
export let selectSelectedGifts = (state: AppState) =>
  state.ui.selectedGifts

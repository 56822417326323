import { GetServerSidePropsContext } from 'next'
import {
  isDiff,
  isNonEmptyArray,
  isNonEmptyString,
  isPresent,
  isSame,
  map,
  pipe,
  reverse,
  split,
  toPairs,
} from './utils'
import { BASE_URL } from 'config/constants'

export let tenMinutesInMs = 600000
export let oneHourInMs = 3600000
export let oneDayInMs = 86400000
export let halfHourInSec = 1800
export let oneHourInSec = 3600
export let halfDayInSec = 43200
export let oneDayInSec = 86400
export let oneWeekInSec = 604800
export let oneMonthInSec = 2592000

export let inProduction = isSame(
  process.env['NODE_ENV'],
  'production'
)

export const createShaSmsToken = async () => {
  const response = await fetch(`${BASE_URL}/v1/auth/getdate`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
  const json = await response.json()
  const serverDate = json.date
  const now = new Date().toISOString().split(':')
  const clientDate = `${now[0]}:${now[1]}`
  const secret = 'poadjivAmt::'
  const text = `${secret}${isPresent(serverDate) ? serverDate : clientDate}`

  const data = new TextEncoder().encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}

export let isServer = isSame(typeof window, 'undefined')

export let wait = async (x = 2000) =>
  await new Promise((resolve) => setTimeout(resolve, x))

export let encodeQuery = (x = '') => x.replace(/\+/g, '%2B')
export let decodeQuery = (x = '') => x.replace(/%2B/g, '+')

export let getTextByQty = (
  x: number,
  rest: string,
  when1: string,
  when2_4?: string
) =>
  isSame(x, 1)
    ? when1
    : isPresent(when2_4) &&
      ![11, 12, 13, 14].includes(x) &&
      [2, 3, 4].includes(Number(`${x}`.slice(-1)))
    ? when2_4
    : rest

export let setCacheControl = (
  res: GetServerSidePropsContext['res'],
  sMaxage = halfHourInSec,
  stale = oneHourInSec
) => {
  res.setHeader(
    'Cache-Control',
    `s-maxage=${sMaxage}, stale-while-revalidate=${stale}`
  )
}

export let parseQueryString = (
  params: Record<string, any> | null,
  filters: Record<string, any> | null
) => {
  let queryParams = toPairs(params ?? {})
    .map((a) => isPresent(a[1]) && `${a[0]}=[${a[1]}]`)
    .filter((a) => isPresent(a))
    .join('&')

  let queryFilters = toPairs(filters ?? {})
    .map((a) =>
      isNonEmptyArray(a[1])
        ? `"${a[0]}":[${a[1]}]`
        : isPresent(a[1]) && isDiff(a[1], '""')
        ? `"${a[0]}":${a[1]}`
        : null
    )
    .filter((a) => isPresent(a))
    .join(',')

  let queryString = [
    queryParams,
    isNonEmptyString(queryFilters) ? `filter={${queryFilters}}` : '',
  ]
    .filter(isNonEmptyString)
    .join('&')

  return isNonEmptyString(queryString) ? `?${queryString}` : ''
}

export let prepareCoords = (coords: string) =>
  pipe(coords, split(' '), map(Number), reverse)

export const formatPhone = (phone: string) => {
  if (phone && phone.trim().length === 12) {
    return `+ ${phone.charAt(1)} ${phone.substring(
      2,
      5
    )} ${phone.substring(5, 8)}-${phone.substring(
      8,
      10
    )}-${phone.substring(10, 12)}`
  }
  return phone
}

import { PromiseReturnType } from '@types'
import type { getAllProducts } from 'lib/getAllProducts'
import {
  isAbsent,
  isEmptyArray,
  isEmptyObj,
  isSame,
  toPairs,
} from 'lib/utils'
import { AppState } from 'store'
import { IAuthState } from 'store/auth'
import {
  IBanner,
  ICategory,
  IGetAboutResult,
  IGetCartResult,
  IGetCitiesResult,
  IGetOrder,
  IGetUserInfo,
  IProduct,
  ITooltipInfo,
  type IGetCartIntervalsResult,
} from '.'

export let selectBannersList =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) => {
    return state.api.queries[`getBanners("${lng}")`]?.data as
      | { banners: IBanner[]; other_banners: IBanner[] }
      | undefined
  }

export let selectTooltipList =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) => {
    return state.api.queries[`getTooltips("${lng}")`]?.data as
      | ITooltipInfo[]
      | undefined
  }

export let selectProductList =
  (lng: NonNullable<IAuthState['lng']>, withAll = false) =>
  (state: AppState) => {
    let city = state.auth.city

    let getProductListData =
      (state.api.queries[`getProductList("${lng}")`]
        ?.data as PromiseReturnType<typeof getAllProducts>) ?? {}

    let allProducts = getProductListData.products ?? {}

    return isSame(city, null) || withAll
      ? allProducts
      : toPairs(allProducts).reduce((acc, [categoryId, products]) => {
          if (isAbsent(acc[categoryId])) {
            acc[categoryId] = []
          }

          products.forEach(
            (product) =>
              product.cities.includes(city!) &&
              acc[categoryId]!.push(product)
          )

          isEmptyArray(acc[categoryId]) && delete acc[categoryId]

          return acc
        }, {} as Record<string, IProduct[]>)
  }

export let selectProductQty =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) => {
    let getProductListData =
      (state.api.queries[`getProductList("${lng}")`]
        ?.data as PromiseReturnType<typeof getAllProducts>) ?? {}

    return getProductListData.qty ?? {}
  }

export let selectCategoryList =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    state.api.queries[`getCategoryList("${lng}")`]?.data as
      | ICategory[]
      | undefined

export let selectCart =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    (
      state.api.queries[`getCart("${lng}")`]?.data as {
        data: { cart: IGetCartResult | undefined }
      }
    )?.data.cart ?? {
      items: [] as IGetCartResult['items'],
      errors: [] as IGetCartResult['errors'],
      applied_coupons: [] as IGetCartResult['applied_coupons'],
      need_select: [] as IGetCartResult['need_select'],
      cartMessages: [] as IGetCartResult['cartMessages'],
      enabled_discounts: [] as IGetCartResult['enabled_discounts'],
      available_discounts:
        [] as IGetCartResult['available_discounts'],
      delivery: { deliveryPrice: 0 } as IGetCartResult['delivery'],
      payment_methods: {
        '': { name: '', short_name: '' },
      } as IGetCartResult['payment_methods'],
      address: undefined,
      payment_type: '' as IGetCartResult['payment_type'],
      products_sum: 0,
      original_sum: 0,
      sum: 0,
      groupsingsProps: [],
    }

export let selectCartIntervals =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) => {
    const allIntervals =
      (
        state.api.queries[`getCartIntervals("${lng}")`]?.data as {
          data: IGetCartIntervalsResult | undefined
        }
      )?.data ?? ({} as IGetCartIntervalsResult)

    if (isEmptyObj(allIntervals))
      return {
        nearestInterval: '',
        restIntervals: {},
      }

    const [nearestDay, firstDayItems] = toPairs(allIntervals)[0]!
    const { label } = firstDayItems[0]!

    const nearestInterval = `${nearestDay.replace(
      /-/g,
      '.'
    )} ${label.replace('-', '–')}`

    const restIntervals = isSame(firstDayItems.length, 1)
      ? Object.fromEntries(Object.entries(allIntervals).slice(1))
      : allIntervals

    return { nearestInterval, restIntervals }
  }

export let selectCities =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    (state.api.queries[`getCities("${lng}")`]?.data as
      | IGetCitiesResult[]
      | undefined) ?? []

export let selectUser =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    (
      state.api.queries[`getUserInfo("${lng}")`]?.data as {
        data: { user: IGetUserInfo | undefined }
      }
    )?.data.user ?? {
      phone: '' as IGetUserInfo['phone'],
      email: '' as IGetUserInfo['email'],
      birth_date: '' as IGetUserInfo['birth_date'],
      first_name: '' as IGetUserInfo['first_name'],
      profiles: [] as IGetUserInfo['profiles'],
      settings: {} as IGetUserInfo['settings'],
    }

export let selectOrders =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    (
      state.api.queries[`getOrders("${lng}")`]?.data as {
        data: { orders: IGetOrder[] | undefined }
      }
    )?.data.orders ?? []

export let selectOrder =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    (
      state.api.queries[`getOrders("${lng}")`]?.data as {
        data: { orders: IGetOrder[] | undefined }
      }
    )?.data.orders ?? []

export let selectAbout =
  (lng: NonNullable<IAuthState['lng']>) => (state: AppState) =>
    (state.api.queries[`getAbout("${lng}")`]?.data as
      | IGetAboutResult[]
      | undefined) ?? []

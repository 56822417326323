import { BASE_URL } from 'config/constants'
import { IProduct } from 'store/api'
import { IAuthState } from 'store/auth'
import fetcher from './fetcher'
import {
  allSettled,
  isAbsent,
  isSame,
  toPairs,
  toValues,
} from './utils'

export let getAllProducts = async (lng: string) => {
  let products: Record<string, IProduct[]> | undefined

  let cities = await fetcher({
    url: `${BASE_URL}/v1/cities/indexnew`,
    headers: { lng },
  })

  let citiesCodes = cities.error
    ? []
    : (cities.data as any).cities.map((x: any) => x.code)

  let _products = (
    await allSettled(
      //toKeys(CITIES).map(async (x) => {
      citiesCodes.map(async (x: string) => {
        let getProductsResult: any = await fetcher({
          url: `${BASE_URL}/v1/categories/shortproductsv2`,
          headers: { lng, city: x },
        })
        if (getProductsResult.error) return [x, []]

        return [x, getProductsResult.data.products]
      })
    )
  ).reduce((acc, x) => {
    if (x.status === 'fulfilled') {
      acc[x.value[0] as NonNullable<IAuthState['city']>] = x.value[1]
    }
    return acc
  }, {} as Record<NonNullable<IAuthState['city']>, Record<string, Omit<IProduct, 'cities'>[]>>)

  let qty = toPairs(_products).reduce((acc, [city, categoryList]) => {
    if (isAbsent(acc[city])) {
      acc[city] = 0
    }

    toValues(categoryList).forEach((products) => {
      acc[city] += products.length
    })

    return acc
  }, {} as Record<NonNullable<IAuthState['city']>, number>)

  products = toPairs(_products).reduce(
    (acc, [city, categoryList]) => {
      toPairs(categoryList).forEach(([categoryId, products]) => {
        if (isAbsent(acc[categoryId])) {
          acc[categoryId] = []
        }

        products.forEach((x) => {
          let productIdx = acc[categoryId]!.findIndex((y) =>
            isSame(y.id, x.id)
          )

          isSame(productIdx, -1)
            ? acc[categoryId]!.push({
                ...x,
                cities: [city],
              })
            : acc[categoryId]![productIdx]!.cities.push(city)
        })
      })

      return acc
    },
    {} as Record<string, IProduct[]>
  )

  return { products, qty }
}

import { createAction, createReducer } from '@reduxjs/toolkit'
import { AppState } from '.'

export type ICheckoutState = {
  user_name?: string
  user_email?: string

  drive?: string
  room?: string
  floor?: string
  intercom?: string

  comments?: string
  // persons?: string

  change?: string

  entity_name?: string
  inn?: string
  kpp?: string
  entity_address?: string

  is_another_person?: boolean
  another_person?: string
  another_phone?: string

  delivery_type?: 'delivery' | 'pickup'
  nearest?: string | null
}

/*
 * Actions
 */
export let setCheckoutData = createAction<ICheckoutState>(
  'checkout/setCheckoutData'
)
export let setIsAnotherPerson = createAction<boolean>(
  'checkout/setIsAnotherPerson'
)

export let setDeliveryType = createAction<'delivery' | 'pickup'>(
  'checkout/setDeliveryType'
)

export let setNearest = createAction<string>('checkout/setNearest')

/*
 * Reducer
 */
let initialState: ICheckoutState = {
  is_another_person: false,
  delivery_type: 'delivery',
  nearest: null,
}

export let checkoutReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setCheckoutData, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          is_another_person: state.is_another_person,
          delivery_type: state.delivery_type,
        }
      })
      .addCase(setIsAnotherPerson, (state, { payload }) => {
        state.is_another_person = payload
      })
      .addCase(setDeliveryType, (state, { payload }) => {
        state.delivery_type = payload
      })
      .addCase(setNearest, (state, { payload }) => {
        state.nearest = payload
      })
  }
)

/*
 * Selectors
 */
export let selectCheckoutData = (state: AppState) => state.checkout
export let selectIsAnotherPerson = (state: AppState) =>
  state.checkout.is_another_person
export let selectDeliveryType = (state: AppState) =>
  state.checkout.delivery_type
export let selectNearest = (state: AppState) => state.checkout.nearest
